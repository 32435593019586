import { ChangeEvent, FocusEvent, forwardRef, InputHTMLAttributes, ReactNode } from 'react'

import styled, { css } from 'styled-components'

import { Field } from 'core/hooks/useForm'
import { variables } from 'core/styles'

export type InputVariant = 'outline' | 'underline' | 'none'

export type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'value' | 'onChange'> &
  Field<string> & {
    className?: string
    variant?: InputVariant
    width?: string
    prefix?: ReactNode
    postfix?: ReactNode
    format?: 'string' | 'currency' | 'float' | 'integer'
  }

const amountPattern = /^\d*([.]?\d{0,2})?$/
const integerPattern = /^\d*$/

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      prefix,
      postfix,
      variant,
      width,
      disabled,
      className,
      placeholder,
      onChange,
      onBlur,
      format = 'string',
      ...props
    },
    ref,
  ) => {
    const common = {
      disabled,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        if (
          format === 'string' ||
          (format === 'currency' && amountPattern.test(value)) ||
          (format === 'float' && Number.isFinite(Number(value))) ||
          (format === 'integer' && integerPattern.test(value))
        ) {
          onChange?.(value)
        }
      },
      onBlur: (e: FocusEvent<HTMLInputElement>) => {
        if (format !== 'string' && e.target.value !== '') {
          const value =
            format === 'currency' ? Number(e.target.value).toFixed(2)
            : format === 'float' ? String(Number(e.target.value))
            : Number(e.target.value).toFixed(0)

          if (value !== e.target.value) {
            onChange?.(value)
          }
        }

        return onBlur?.(e)
      },
      placeholder:
        placeholder ??
        (format === 'currency' ? '0.00'
        : format === 'float' ? '0.0'
        : format === 'integer' ? '0'
        : undefined),
    }

    const _prefix = prefix ?? (format === 'currency' ? '$' : undefined)

    return _prefix || postfix ?
        <Wrapper className={className} variant={variant} width={width} disabled={disabled}>
          <Fixture>{_prefix}</Fixture>
          <input ref={ref} {...props} {...common} />
          <Fixture>{postfix}</Fixture>
        </Wrapper>
      : <StyledInput ref={ref} className={className} variant={variant} width={width} {...props} {...common} />
  },
)

const Fixture = styled.span`
  color: ${variables.colorBlack60};
`

export const common = css<{ width?: string; variant?: InputVariant; disabled?: boolean }>`
  box-sizing: border-box;
  width: ${(p) => (p.width ? p.width : 'auto')};
  border-color: transparent;
  border-style: solid;
  border-width: 0 0 1px;

  ${(p) =>
    p.disabled &&
    `
    // Matching the ReactSelect default styles (?) here for consistency
    color: #999999;
    background-color: #f2f2f2;
    border-color: #e6e6e6;
  `}

  ${(p) =>
    (p.variant === 'underline' || p.variant === 'outline') &&
    `
      border-color: ${variables.colorBlack20};

      &:hover {
        border-color: ${variables.colorBlack30};
      }
  `}

  ${(p) =>
    p.variant === 'outline' &&
    `
      padding: 8px;
      border-width: 1px;
      border-radius: 4px;
    `}

  &:focus-within:not([readonly]) {
    border-color: ${variables.colorBlueSecondary};
    outline: none;
  }
`

export const commonInput = css`
  font-family: inherit;
  font-size: 14px;
  color: inherit;
  background-color: transparent;
  border: none;

  ::placeholder {
    color: ${variables.colorBlack50};
  }
`

export const Wrapper = styled.div<{ width?: string; variant?: InputVariant; disabled?: boolean }>`
  ${common}
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  column-gap: 2px;
  align-items: center;

  input {
    ${commonInput}
    &:focus-within {
      border: none;
      outline: none;
    }
  }
`

const StyledInput = styled.input<{ width?: string; variant?: InputVariant; disabled?: boolean }>`
  ${commonInput}
  ${common}
`

export default TextInput
