import React from 'react'

import * as changeCase from 'change-case'
import { UseFormRegisterReturn, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

import TextInput from 'core/components/legacy/TextInput'

import { PeachOverrideName, PeachOverrideVariant } from './overrideOptions'

type OverrideProps = Omit<UseFormRegisterReturn<PeachOverrideName>, 'ref'> & {
  override: PeachOverrideVariant
}

const Override = React.forwardRef<any, OverrideProps>(({ override, ...props }, ref) => {
  const requirement = useWatch({ name: override.requires?.name ?? '', disabled: !override.requires })

  if (override.requires && requirement !== override.requires.value) return null

  switch (override.type) {
    case 'select':
      return (
        <>
          <StyledLabel>{changeCase.sentenceCase(props.name)}</StyledLabel>
          <StyledSelectInput ref={ref} {...props}>
            {override.options.map((o) => (
              <option key={`${props.name}-${o}`} value={o}>
                {changeCase.sentenceCase(o)}
              </option>
            ))}
          </StyledSelectInput>
        </>
      )
    case 'text':
      return (
        <>
          <StyledLabel>{changeCase.sentenceCase(props.name)}</StyledLabel>
          <StyledTextInput ref={ref} placeholder={override.placeholder} {...props} />
        </>
      )
    case 'toggle':
      return (
        <StyledLabel htmlFor={props.name}>
          <input ref={ref} id={props.name} type='checkbox' {...props} />
          {changeCase.sentenceCase(props.name)}
        </StyledLabel>
      )

    default:
      return null
  }
})

export default Override

const inputStyles = css`
  box-sizing: border-box;
  width: 100%;
  padding: 2px 4px;
  font-size: 12px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 1px;
`

const StyledTextInput = styled(TextInput)`
  ${inputStyles}
`

const StyledSelectInput = styled.select`
  ${inputStyles}
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;

  input {
    margin-right: 8px;
  }
`
