import { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'

import AlertDialog from 'core/components/lib/AlertDialog'
import ConfirmDialog from 'core/components/lib/ConfirmDialog'
import IconButton from 'core/components/lib/IconButton'
import { useHasPermission } from 'core/hooks'
import { variables } from 'core/styles'
import { Permission } from 'core/types'

type SensitiveContentProps = PropsWithChildren<{
  className?: string
  value:
    | {
        sensitiveBorrowerOnly?: boolean | null
        sensitive?: boolean | null
      }
    | undefined
  permissions?: Permission | Permission[]
}>

export const SensitiveContent: FC<SensitiveContentProps> = ({
  className,
  value,
  permissions = 'interaction:read.sensitive',
  children,
}) => {
  const canReadSensitive = useHasPermission(permissions)

  return (
    value?.sensitiveBorrowerOnly ?
      <Sensitive className={className}>
        "Account Credential" interactions are never displayed in the CRM because they contain information used to verify
        borrower details
      </Sensitive>
    : value?.sensitive && !canReadSensitive ?
      <Sensitive className={className}>
        Content is locked. Only those with access, like a manager, can unlock this content.
      </Sensitive>
    : <>{children}</>
  )
}

type LockButtonProps = {
  color?: string
  lockedColor?: string
  disabled?: boolean
  value: boolean | undefined | null
  onChange: (value: boolean) => Promise<unknown>
  permissions: Permission | Permission[]
  desc?: string
}

const LockButton: FC<LockButtonProps> = ({
  disabled = false,
  value = true,
  onChange,
  permissions,
  desc = 'content',
}) => {
  const hasPermissions = useHasPermission(permissions)

  return (
    !value ?
      <ConfirmDialog
        title={`Lock ${desc}`}
        content={`Are you sure you want to lock this ${desc}? Only CRM users with access will be able to view, unlock, or (if applicable) listen to it.`}
        onConfirm={() => onChange(true)}
      >
        <IconButton name='lock_open' checked={false} outlined disabled={disabled} />
      </ConfirmDialog>
    : hasPermissions ? <IconButton onClick={() => onChange(false)} name='lock' checked disabled={disabled} />
    : <AlertDialog title={`Cannot unlock ${desc}`} content={`You do not have permission to unlock this ${desc}`}>
        <IconButton name='lock' checked disabled={disabled} />
      </AlertDialog>
  )
}

export default LockButton

const Sensitive = styled.div`
  padding: 16px 24px;
  font-size: 14px;
  font-style: italic;
  color: ${variables.colorBlack60};
  text-align: center;
`
