import React, { createContext, useCallback, useContext, useMemo } from 'react'

import { useOpenResourceContext } from 'contexts/OpenResourceContext'
import { v4 as uuid4 } from 'uuid'

import { Case, InteractionChannel } from 'core/types'

import { useStoredState } from 'admin/hooks'

export type NewDraftInteraction = {
  associatedCase?: Case
  borrowerId?: string
  channel?: InteractionChannel
  companyId?: string
  previousInteractionId?: string
  supercaseId?: string
  fieldValues?: Record<string, any>
  isExternal?: boolean
}

export type DraftInteraction = NewDraftInteraction & {
  id: string
}

type DraftInteractionById = {
  [key: string]: DraftInteraction
}

export type DraftInteractionContextProps = {
  draftInteractionsById: DraftInteractionById
  setDraftInteractionsById: (value: DraftInteractionById) => void
  onOpenNewDraftInteraction: (value: NewDraftInteraction) => void
  onUpdateDraftInteraction: ({ id, newState }: { id: string; newState: DraftInteraction }) => void
  onDeleteDraft: (id: string) => void
}

const DraftInteractionContext = createContext<DraftInteractionContextProps>({} as DraftInteractionContextProps)

const DraftInteractionContextProvider = ({ children }: any) => {
  const { openDraftInteraction } = useOpenResourceContext() as any
  const [draftInteractionsById, setDraftInteractionsById] =
    useStoredState<DraftInteractionById>('draftInteractionsById')

  const onOpenNewDraftInteraction = useCallback(
    (partial: NewDraftInteraction) => {
      const draft = {
        ...partial,
        id: uuid4(),
        fieldValues: {},
      } satisfies DraftInteraction
      openDraftInteraction(draft)
      setDraftInteractionsById({
        ...draftInteractionsById,
        [draft.id]: draft,
      })
    },
    [openDraftInteraction, draftInteractionsById, setDraftInteractionsById],
  )

  const onUpdateDraftInteraction = useCallback(
    ({ id, newState: newDraftInteraction }: { id: string; newState: NewDraftInteraction }) => {
      setDraftInteractionsById((prevState) => {
        if (!prevState[id]) return prevState
        return { ...prevState, [id]: { id: id, ...newDraftInteraction } }
      })
    },
    [setDraftInteractionsById],
  )

  const onDeleteDraft = useCallback(
    (id: string) => {
      setDraftInteractionsById((prevState) => {
        const { [id]: removedItem, ...rest } = prevState
        return rest
      })
    },
    [setDraftInteractionsById],
  )

  const value = useMemo(() => {
    return {
      draftInteractionsById,
      setDraftInteractionsById,
      onOpenNewDraftInteraction,
      onUpdateDraftInteraction,
      onDeleteDraft,
    }
  }, [
    draftInteractionsById,
    setDraftInteractionsById,
    onOpenNewDraftInteraction,
    onUpdateDraftInteraction,
    onDeleteDraft,
  ])

  return <DraftInteractionContext.Provider value={value}>{children}</DraftInteractionContext.Provider>
}

export const useDraftInteractionContext = () => useContext(DraftInteractionContext)

export default DraftInteractionContextProvider
